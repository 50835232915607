<template>
    <div
        class="mx-auto flex h-full min-h-[100dvh] w-full grid-cols-1 flex-col lg:min-h-screen"
    >
        <div class="sticky top-0 z-[52] w-full bg-black lg:relative">
            <!-- header component need to be responsive -->
            <ThaLayoutHeaderV2Topper />
        </div>
        <!-- header component need to be responsive -->
        <ThaLayoutHeaderV2Menu />

        <section v-if="$route.name === 'index'" class="block lg:hidden">
            <ThaBlocksHomeSwiperV3 />
        </section>
        <main
            class="mx-auto grid w-full flex-1 grid-cols-[70px_minmax(100px,_1fr)] lg:grid-cols-1"
        >
            <div class="col-span-full mb-[70px] lg:mb-0">
                <slot />
            </div>
        </main>

        <div class="fixed bottom-0 z-50 w-full lg:relative lg:z-[1]">
            <div class="hidden h-full bg-black lg:flex">
                <ThaLayoutFooterV2 />
            </div>

            <div class="flex lg:hidden">
                <LayoutMobileBottomNavbar />
            </div>
        </div>
        <ModalFunds />
        <ModalSupportChannelV2 />
        <ModalLogin :show="showLoginModal" />
        <ModalSignup :show="showSignUpModal" />
        <ModalForgotPassword :show="showForgotPasswordModal" />
        <ModalGameDeny :show="showGameDenyModal" />
        <ModalLogout :show="showLogoutModal" />
        <ModalVerifyOtp :show="showVerifyOTPModal" />
        <CommonAlert />
        <ModalKyc />
        <ThaCommonHotline />
        <ModalKycRequired />
        <ModalKycVerificationPending />
        <ModalCancelPromo />
        <ModalBtiPromo :show="showPromoBtiModal" />
        <cashback_rebate_modal
            v-if="showCashbackRebateModal"
            :data="cashback_rebate"
        />
    </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useModalStore } from '~/stores'
// import { useAlertStore, useUserStore } from '~/stores'
const route = useRoute()
const useUserStoreInstance = useUserStore()
const { isLoggedIn } = storeToRefs(useUserStoreInstance)

const metaUrl = [
    {
        url: '/',
        name: 'home',
    },
    {
        url: '/help?tab=about-us',
        name: 'about_us',
        queryFullPath: true,
    },
    {
        url: '/help?tab=terms',
        name: 'terms',
        queryFullPath: true,
    },
    {
        url: '/help?tab=policy',
        name: 'policy',
        queryFullPath: true,
    },
    {
        url: '/guides',
        name: 'guides',
    },
    {
        url: '/sports',
        name: 'sports',
    },
    {
        url: '/e-sports',
        name: 'e_sports',
    },
    {
        url: '/casino/all',
        name: 'casino',
    },
    {
        url: '/games/jackpot',
        name: 'games_jackpot',
    },
    {
        url: '/games/ingame',
        name: 'games_ingame',
    },
    {
        url: '/games/slots',
        name: 'games_slots',
    },
    {
        url: '/games/fishing',
        name: 'games_shooting',
    },
    {
        url: '/games/instants',
        name: 'games_instant',
    },
    // {
    //     url: '/lottery',
    //     name: 'lottery',
    // },
]
const { t } = useI18n()
const SITE_URL = useRuntimeConfig().public.SITE_URL

const seoInfo = () => {
    const metaContent =
        metaUrl.find((i) => {
            if (i.queryFullPath) {
                return i.url === route.fullPath
            }
            return i.url === route.path
        }) || metaUrl[0]
    useSeoMeta({
        title: t(`meta.${metaContent.name}.title`),
        description: t(`meta.${metaContent.name}.description`),
        keywords: t(`meta.${metaContent.name}.keywords`),
        canonical: `${SITE_URL}${route.fullPath}`,
    })
}

// const isBgGray = computed(() => {
//     const { isMobileOrTablet } = useDevice()
//     const listRouter = [
//         '/virtual-sport',
//         '/sports',
//         '/',
//         '/casino/all?partner=',
//         '/games/fishing?partner=',
//         '/games/cockfight?partner=',
//         '/games/tables?partner=',
//         '/games/slots?partner=',
//         '/lottery',
//         '/games/instants?partner=',
//         '/e-sports',
//     ]
//     if (listRouter.includes(useRoute().fullPath) && isMobileOrTablet) {
//         return true
//     }
//     return false
// })

watchEffect(() => {
    seoInfo()
})

// const useAlertStoreInsatance = useAlertStore()
const useModalStoreInstance = useModalStore()
const {
    showLoginModal,
    showSignUpModal,
    showForgotPasswordModal,
    showGameDenyModal,
    showLogoutModal,
    showVerifyOTPModal,
    showPromoBtiModal,
    showCashbackRebateModal,
} = storeToRefs(useModalStoreInstance)

const showLoggedOutAlert = useState('showLoggedOutAlert', () => false)
// const errorObject = ref({
//     message_key: 'error.logged_out',
//     type: 'ERROR',
// })
watch(
    () => showLoggedOutAlert.value,
    (newVal, _) => {
        if (newVal) {
            // useAlertStoreInsatance.showMessage(errorObject.value)
            showLoggedOutAlert.value = false
        }
    }
)

const openModalRefrral = () => {
    if (route.query.ref && route.query.register && !isLoggedIn.value) {
        showSignUpModal.value = true
    }
}

const cashback_rebate_modal = resolveComponent(
    'tha-blocks-account-promotions-cashback_rebate_modal'
)
const cashback_rebate = ref({})
const { execute, data } = useFetch('/v1/popup', {
    baseURL: '/api-promotion',
    pick: ['data', 'status'],
    immediate: false,
})
const checkRebatePromo = async () => {
    await useUserStoreInstance.refresh()
    await execute()
    if (data?.value?.status === 'OK' && data?.value?.data?.length > 0) {
        // pass first item for modal
        cashback_rebate.value = data.value.data[0]
        setTimeout(() => (showCashbackRebateModal.value = true), 1000)
    }
}
watch(
    () => isLoggedIn.value,
    async (newVal) => {
        if (newVal) {
            await checkRebatePromo()
        }
    }
)

onMounted(async () => {
    await nextTick()
    openModalRefrral()
    if (isLoggedIn.value) {
        await checkRebatePromo()
    }
})
</script>
